@import 'ui/icon/icon-variables.css';

:root {
  --default-font-icon-offset: -0.1; /* THEMING API */

  /* Use this variable for icons floating in text. */
  --floating-icon-transform: translateY(calc(var(--default-font-icon-offset) * 1em));
}

.d-icon {
  --icon-color: currentColor;

  display: inline-block;
  flex-shrink: 0;
  height: var(--icon-size);
  margin: 0;
  vertical-align: middle;
  width: var(--icon-size);

  & path,
  & circle,
  & ellipse,
  & rect {
    &:where(:not([fill], [stroke])) {
      fill: var(--icon-color);
    }
  }

  & path:where([stroke]) {
    stroke: var(--icon-color);
    stroke-width: 1.5px;
  }

  &.css-based-icon {
    background-color: var(--icon-color);

    /* noinspection CssUnresolvedCustomProperty */
    mask-image: var(--mask-image); /* Variable --mask-image gets filled from icon.ts,
    that's why we ignore the rule CssUnresolvedCustomProperty here */
  }
}

.d-icon-xxs {
  --icon-size: 12px;
}

.d-icon-xs {
  --icon-size: var(--icon-size-xs);
}

.d-icon-s {
  --icon-size: 20px;
}

.d-icon-l {
  --icon-size: 32px;
}

.d-icon-xl {
  --icon-size: 48px;
}

.d-icon-light path {
  stroke-width: 1px;
}

.d-icon-bold path {
  stroke-width: 2px;
}

.d-icon-primary {
  color: var(--primary-color);
}

.d-icon-error {
  color: var(--color-error);
}

.d-icon-warning {
  color: var(--color-warning);
}

.d-icon-stack {
  display: inline-block;
  height: var(--icon-size);
  position: relative;
  vertical-align: middle;
  width: var(--icon-size);

  & .d-icon {
    margin: 0;
    position: absolute;

    &:not(.d-icon-badge) {
      left: 0;
    }
  }

  & .d-icon-badge {
    bottom: -3px;
    height: 12px;
    right: -3px;
    width: 12px;
  }
}
