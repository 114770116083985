.d-page {
  display: grid;
  grid-template-areas: 'header' 'body' 'footer';
  grid-template-rows: auto minmax(0, 1fr) auto; /* https://css-tricks.com/preventing-a-grid-blowout/ */
  height: 100%;
}

.d-page--header:empty,
.d-page--footer:empty {
  display: none; /* Hide empty headers and footers, so that they're not read by screen readers. */
}

.d-page--header {
  grid-area: header;
}

.d-page--banner {
  @media (--xs-only) {
    display: none;
  }
}

.d-page--content {
  grid-area: body;
  overflow-y: auto;
  position: relative;
}

.d-page--footer {
  grid-area: footer;
}

.navigator,
.navigator + .d-sidebar--backdrop {
  grid-area: body; /* Same area, because the navigator overlaps the content. */
}
