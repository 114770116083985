input[type=radio] {
  --radio-size: 18px;
  --radio-dot-size: 5px;

  appearance: none;
  background-color: #fff;
  background-position: center;
  border: 1px solid var(--gray-04);
  border-radius: 50%;
  display: inline-block;
  height: var(--radio-size);
  margin-bottom: -1px;
  margin-top: -1px;
  transition:
    background-color var(--default-transition),
    box-shadow var(--default-transition);
  width: var(--radio-size);

  &:checked {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSI1IiBmaWxsPSIjMzUzYjQ0Ii8+PC9zdmc+');
  }

  &:focus {
    background-color: #ccc;
    outline: none;
  }

  &:disabled {
    opacity: 0.25;
  }

  &:hover:not(:disabled) {
    background-color: #e6e6e6;
  }

  .was-validated &:invalid,
  .is-invalid:not(.is-disabled) & {
    background-color: var(--color-error-10);
    border-color: var(--color-error);
  }
}

@media (--touch) {
  input[type=radio] {
    --radio-size: 22px;
    --radio-dot-size: 7px;
  }
}

.is-keynav .d-form-check input[type=radio]:focus {
  box-shadow: var(--default-outline);
}
