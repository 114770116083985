@import './page.css';
@import './header.css';
@import './responsive-utils.css';
@import './scrollbar.css';
@import './toolbar.css';
@import './typography.css';

/* Utils */

@import './utils/background.css';
@import './utils/blink.css';
@import './utils/ellipsis.css';
@import './utils/focus-outline.css';
@import './utils/is-hover-visible.css';
@import './utils/is-hover-displayed.css';
@import './utils/layout.css';
@import 'ui/utils/visuallyhidden.css';

/* UI */

@import 'ui/anchor/anchor.css';
@import 'ui/button/button.css';
@import 'ui/checkbox/checkbox.css';
@import 'ui/form-check/form-check.css';
@import 'ui/form-control/form-control.css';
@import 'ui/form-group/form-group.css';
@import 'ui/icon/icon.css';
@import 'ui/panel-section/panel-section.css';
@import 'ui/radio/radio.css';
@import 'ui/separator/separator.css';
@import 'ui/stack/stack.css';

/* More base styles */

* {
  box-sizing: border-box;
}

.d-spacer {
  align-self: stretch;
  flex: 1;
  margin: 0;
}

mark {
  background-color: yellow;
  color: var(--gray-08);
}

/**
 * Normalize the [hidden] behavior (see https://meowni.ca/hidden.is.a.lie.html).
 *
 * The CSS class is needed, because the attribute cannot be set conditionally via JSF.
 */
[hidden],
.hidden {
  display: none !important;
}

/* https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html */
@media (prefers-reduced-motion: reduce) {
  *:not(.progress-spinner),
  *::before,
  *::after,
  *::backdrop {
    animation-duration: 0s !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
  }
}
