@import './stack-variables.css';

/*
 * Repeat the selector to trump the specificity
 * of other single class selectors.
 */
.d-stack-h.d-stack-h,
.d-stack-v.d-stack-v {
  display: flex;
}

.d-stack-h,
.d-stack-v {
  --space: 0;

  gap: var(--space);
}

.d-stack-h {
  align-items: center;
  flex-direction: row;

  & > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.d-stack-v {
  flex-direction: column;

  & > * {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.d-stack-h,
.d-stack-v {
  &.space-0_25 {
    --space: var(--space-025);
  }

  &.space-0_5 {
    --space: var(--space-05);
  }

  &.space-1 {
    --space: var(--space-1);
  }

  &.space-2 {
    --space: var(--space-2);
  }

  &.space-3 {
    --space: var(--space-3);
  }

  &.space-4 {
    --space: var(--space-4);
  }

  &.space-5 {
    --space: var(--space-5);
  }

  &.space-6 {
    --space: var(--space-6);
  }

  &.space-8 {
    --space: var(--space-8);
  }

  &.space-10 {
    --space: var(--space-10);
  }

  &.space-12 {
    --space: var(--space-12);
  }

  &.space-16 {
    --space: var(--space-16);
  }

  &.space-20 {
    --space: var(--space-20);
  }

  &.space-24 {
    --space: var(--space-24);
  }
}
