.d-truncated-p {
  --max-lines: 3;

  overflow-wrap: anywhere;
  white-space: normal;

  &:not(:is(.is-expanded, [truncated-text])) .d-truncated-p--paragraph-container {

    & p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: var(--max-lines);
      overflow: hidden;
    }
  }

  &.is-expanded > .d-truncated-p--paragraph-ellipsis {
    display: none;
  }

  & .d-anchor {
    align-self: flex-start;
  }
}
