.d-alerts {
  display: block;

  & .d-alert:not(:first-child) {
    margin-top: 15px;
  }
}

.reportWorkflowDownloadIFrame,
.table-results-table,
.chartDynamicContent {
  & .d-alerts:not(:empty) {
    margin: 8px;
  }
}
