@import 'ui/button-variables.css';

.button {
  --button-bg: var(--gray-01);
  --button-bg-active: var(--primary-color);
  --button-bg-disabled: var(--button-bg);
  --button-bg-hover: var(--gray-02);
  --button-color: var(--gray-08);
  --button-color-active: var(--white-09);
  --button-color-disabled: var(--gray-04);

  align-items: center;
  appearance: none;
  background-color: var(--button-bg);
  border: none;
  color: var(--button-color);
  display: inline-flex;
  flex-shrink: 0;
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--bold-font-weight);
  justify-content: center;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition:
    background-color var(--default-transition),
    box-shadow var(--default-transition),
    color var(--default-transition);
  user-select: none;
  white-space: nowrap;
  width: fit-content; /* Do not stretch in column flex layouts */

  &::-moz-focus-inner {
    border: 0;
  }

  &:disabled {
    background-color: var(--button-bg-disabled);
    color: var(--button-color-disabled);
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover:not(:disabled) {
    background-color: var(--button-bg-hover);
  }

  .is-keynav &:focus {
    border-radius: 1px;
    box-shadow: var(--default-outline);
    outline: var(--default-native-outline);
  }

  &:active,
  &.active,
  &[aria-pressed=true],
  &[aria-expanded=true] {
    &:not(:disabled) {
      background-color: var(--button-bg-active);
      color: var(--button-color-active);
    }
  }

  &:not(.button-icon, .button-round) > .d-icon:first-child {
    margin-right: 2px;
  }

  & .button--label {
    cursor: inherit;
  }

  & .button--label + .d-icon {
    margin-left: 4px;
  }
}

.light-bg .button {
  --button-bg: var(--white-09);
}

.dark-bg .button {
  --button-bg: var(--white-01);
  --button-bg-active: var(--white-09);
  --button-bg-disabled: var(--white-01);
  --button-bg-hover: var(--white-02);
  --button-color: var(--white-08);
  --button-color-active: var(--primary-color);
  --button-color-disabled: var(--white-04);
}

/*********
 * Sizes *
 *********/

/* Default Size (M) */

.button {
  --button-size: var(--button-size-m);
  --button-icon-size: 24px;
  --button-padding: 12px;

  height: var(--button-size);
  line-height: var(--button-size);
  min-width: calc(var(--button-size) * 2);
  padding: 0 var(--button-padding);

  & > .d-icon,
  & > .d-icon-stack,
  & > .d-icon-stack > .d-icon {
    height: var(--button-icon-size);
    width: var(--button-icon-size);
  }
}

.button-s {
  --button-size: var(--button-size-s);
  --button-icon-size: 20px;
  --button-padding: 8px;
}

.button-xs {
  --button-size: 20px;
  --button-touch-size: calc(100% - var(--button-size) + var(--button-size-s));
  --button-icon-size: 16px;
  --button-padding: 4px;

  font-size: var(--small-font-size);
  position: relative;

  /* increase the size of the button's touch target without increasing the visual size */
  &::before {
    content: '';
    display: block;
    height: var(--button-touch-size);
    position: absolute;
    width: var(--button-touch-size);
  }

  @media (--touch) {
    --button-size: 24px;
    --button-icon-size: 20px;
  }
}

/* Not an official button size */
.button-xxs {
  --button-size: 16px;
  --button-icon-size: 12px;
  --button-padding: 2px;
}

/***************
 * Icon Button *
 ***************/

.button-icon {
  --button-padding: 4px;

  min-width: 0;
  width: var(--button-size);

  &.button-s {
    --button-padding: 0;
  }

  &.button-xs {
    --button-padding: 1px;
  }

  &.button-xxs {
    --button-padding: 2px;
  }
}

/************************
 * Button with dropdown *
 ************************/

.button-with-dropdown {
  --dropdown-arrow-width: 12px;
  --dropdown-arrow-color: currentColor;

  &::after {
    background-color: var(--dropdown-arrow-color);
    content: '';
    display: inline-block;
    height: 24px;
    mask-image: url('~@disy/cadenza-icons/dropdown-arrow-12x24.svg');
    width: var(--dropdown-arrow-width);

    :not(.button-icon)& {
      margin-left: 4px;
    }
  }
}

.button-icon.button-with-dropdown {
  width: calc(var(--button-size) + var(--dropdown-arrow-width)); /* Adds space for the dropdown */
}

/************
 * Variants *
 ************/

/* Primary */

.button.button-primary {
  --button-bg: var(--primary-color);
  --button-bg-active: var(--primary-color-darken-30);
  --button-bg-disabled: var(--gray-03);
  --button-bg-hover: var(--primary-color-darken-15);
  --button-color: var(--white-09);
  --button-color-active: var(--white-09);
  --button-color-disabled: var(--white-07);
}

.light-bg .button-primary {
  --button-color: var(--gray-01-opaque);
  --button-color-disabled: var(--gray-01-opaque);
}

.dark-bg .button-primary {
  --button-bg: var(--white-08);
  --button-bg-active: var(--white-09);
  --button-bg-disabled: var(--white-03);
  --button-bg-hover: var(--white-09);
  --button-color: var(--gray-08-opaque);
  --button-color-active: var(--primary-color);
  --button-color-disabled: var(--gray-05);
}

/* Borderless */

.button.button-borderless {
  --button-bg: transparent;
  --button-bg-active: var(--gray-02);
  --button-bg-hover: var(--gray-01);
  --button-color: var(--gray-06);
  --button-color-active: var(--primary-color);
  --button-color-disabled: var(--gray-04);

  min-width: var(--button-size);

  &:not(.button-icon) {
    --button-padding: 2px;
  }
}

.button-borderless.button-with-dropdown:not(:disabled) {
  --dropdown-arrow-color: #6d7177;
}

.button-borderless {
  &.button-error {
    --button-color: var(--color-error);
    --button-color-active: var(--color-error-dark);
  }

  &.button-warning {
    --button-color: var(--color-warning-darker);
    --button-color-active: var(--color-warning-dark);
  }

  &.button-success {
    --button-color: var(--color-success);
    --button-color-active: var(--color-success-dark);
  }
}

.dark-bg .button-borderless {
  --button-bg-active: var(--white-02);
  --button-bg-hover: var(--white-01);
  --button-color: var(--white-06);
  --button-color-active: var(--white-09);
  --button-color-disabled: var(--white-04);

  &.button-error {
    --button-color: var(--color-error-lighter);
    --button-color-active: var(--color-error-light);
  }

  &.button-warning {
    --button-color: var(--color-warning);
    --button-color-active: var(--color-warning-light);
  }

  &.button-success {
    --button-color: var(--color-success);
    --button-color-active: var(--color-success-light);
  }
}

/* Link */

.button.button-link {
  --button-bg: transparent;
  --button-bg-active: var(--gray-02);
  --button-bg-hover: var(--gray-01);
  --button-color: var(--link-color);
  --button-color-active: var(--link-color);
  --button-color-disabled: var(--gray-04);
  --button-padding: 2px;

  min-width: var(--button-size);
}

.dark-bg .button-link {
  --button-bg-active: var(--white-02);
  --button-bg-hover: var(--white-01);
  --button-color: var(--link-color-light);
  --button-color-disabled: var(--white-04);
}

/* Round */

.button.button-round {
  --button-size: 48px;
  --button-bg: var(--white-09);
  --button-bg-active: var(--gray-02-opaque);
  --button-bg-hover: var(--gray-01-opaque);
  --button-color: var(--primary-color);
  --button-color-active: var(--primary-color);
  --button-color-disabled: var(--gray-04);
  --button-color-hover: var(--primary-color);

  border-radius: 50%;
  box-shadow: var(--default-box-shadow);
  min-width: 0;
  width: var(--button-size);

  &.button-s {
    --button-size: 36px;
  }
}

/* Rough draft, not ready for implementation */
.dark-bg .button-round {
  --button-bg: var(--gray-08);
  --button-bg-active: var(--gray-06-opaque);
  --button-bg-hover: var(--gray-07-opaque);

  /* --primary-color-light (now #85b5f0) is not defined yet */
  --button-color: #85b5f0;
  --button-color-active: #85b5f0;
  --button-color-disabled: var(--gray-04);
  --button-color-hover: #85b5f0;
}
