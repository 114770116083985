/*
  {https://getbootstrap.com/docs/3.4/css/#responsive-utilities
  Implemented for xs only because
  currently we don't need options like sm-only, md-only....
*/
@media (--xs-only) {
  .hidden-xs {
    display: none !important;
  }
}

@media (--sm-up) {
  .visible-xs {
    display: none !important;
  }
}
