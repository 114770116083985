@import 'ui/common-variables.css';
@import 'ui/scroll-shadow-variables.css';

.d-scroll-header,
.d-scroll-footer {
  transition: box-shadow 400ms;
  z-index: 1;
}

.d-scroll-header {
  border-bottom: 1px solid var(--gray-03);

  &.has-shadow {
    box-shadow: 0 var(--scroll-shadow-header-v-offset) 3px -3px var(--gray-04);
  }
}

.d-scroll-footer {
  border-top: 1px solid var(--gray-03);

  &.has-shadow {
    box-shadow: 0 var(--scroll-shadow-footer-v-offset) 3px -3px var(--gray-04);
  }
}

.d-scroll-column {
  border-right: 1px solid var(--gray-03);

  &.has-shadow {
    box-shadow: 3px 0 3px -2px var(--gray-04);
  }
}

.d-scroll-shadow {
  height: 4px;
  position: absolute;
  transition: opacity 150ms;
  z-index: 2;

  &:not(.is-visible) {
    opacity: 0;
  }
}

.d-scroll-shadow--top {
  background-image: radial-gradient(var(--scroll-shadow-top-background-gradient));
}

.d-scroll-shadow--bottom {
  background-image: radial-gradient(var(--scroll-shadow-bottom-background-gradient));
}

.d-sticky-shadow {
  box-shadow: 3px 5px 6px -2px var(--gray-04);
}
