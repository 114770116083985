@import './form-group-variables.css';

/* stylelint-disable selector-max-type */
:is(form-group, .form-group) {
  --header-spacing: space-between;
  --max-width: var(--form-group-width);
  --label-color: inherit;
  --label-padding: 2px; /* Used also in form-group.module.css */
  --label-line-height: 1.14; /* Used also in form-group.module.css */

  @media (--touch) {
    --label-line-height: 1.25;
  }

  display: block;
  max-width: var(--max-width);
  position: relative;

  &:where(:not(:last-child)) {
    margin-bottom: var(--form-group-margin-bottom);
  }

  /*
   * Hide slot elements (except in the label and default slots)
   * in not yet upgraded <form-group> elements.
   */

  &:not(:defined) [slot]:not([slot=label]) {
    display: none;
  }

  /* Label */

  & > label:first-of-type {
    color: var(--label-color);
    font-weight: var(--bold-font-weight);
    line-height: var(--label-line-height);

    /* The label is different when it's slotted into the Shadow DOM. */

    &:not([slot=label]) {
      margin-bottom: 2px;

      &:not(.d-form-check) {
        display: block;
      }
    }

    &:not(.d-form-check) {
      padding: var(--label-padding) 0;
    }

    &.d-form-check {
      display: inline-flex;
      width: auto;
    }

    /*
     * Elements in the label slot should keep distance.
     * Using margin for this, because we're in an inline flow layout.
     */

    & ~ [slot=label] {
      margin-left: 4px;
    }
  }

  /* States */

  &.is-invalid {
    --label-color: var(--color-error);
  }

  &.is-disabled {
    --label-color: var(--gray-03);

    /*
     * Hide slot elements (except in the label and default slots)
     * in disabled <form-group> elements.
     *
     * Using visibility: hidden instead of display: block to
     * avoid layout jank when enabling the form-group again.
     */

    & > [slot]:not([slot=label]),
    & > [slot=label]:not(label:first-of-type) {
      visibility: hidden;
    }
  }
}

.form-group--required-indicator {
  display: contents; /* Avoid issues with labels that are flex containers. */

  .form-group:is(.is-disabled, .is-readonly) & {
    display: none;
  }
}

/* Size Variants */

.form-group-xs {
  --max-width: var(--form-group-width-xs);
}

.form-group-s {
  --max-width: var(--form-group-width-s);
}

.form-group-l {
  --max-width: var(--form-group-width-l);
}

.form-group-flex {
  --max-width: none;
}
