:root {
  --default-component-size: 32px;
  --small-component-size: 24px;
  --default-view-header-height: 40px;
  --map-toolbar-height: 40px;
  --default-page-margin: 8px;
}

@media (--sm-up) {
  :root {
    --default-page-margin: 16px;
  }
}

@media (--touch) {
  :root {
    --default-component-size: 44px;
    --small-component-size: 30px;
    --default-view-header-height: 56px;
    --map-toolbar-height: 56px;
  }
}
