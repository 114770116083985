@import 'ui/responsive-variables.css';
@import 'ui/icon/icon-variables.css';

.d-compact-header {
  align-items: center;
  border-bottom: 1px solid var(--gray-05);
  display: flex;
  flex-shrink: 0;
  margin: 0 var(--default-page-margin); /* not padding, because of the border */
  padding: 11px 0 4px; /* 12px - 1px border = 11px */

  & > .d-icon {
    height: 18px;
    margin-right: 4px;
    width: 18px;
  }

  & .button:not(:first-child) {
    margin-left: 4px;
  }
}

.d-compact-header--title {
  color: var(--gray-08-opaque);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--bold-font-weight);
  line-height: var(--icon-size);
  margin: 0 auto 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}
