.d-progress-spinner-overlay {
  align-items: center;
  background-color: var(--white-08);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  & .d-progress-spinner.spinner-over-content {
    height: auto;
    margin-bottom: 16px;
  }
}
