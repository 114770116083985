hr,
[role=separator] {
  --separator-color: var(--gray-03);
  --separator-border: 1px solid var(--separator-color);

  &.d-separator-dark {
    --separator-color: var(--gray-05);
  }
}

.dark-bg :is(hr, [role=separator]) {
  --separator-color: var(--white-03);

  &.d-separator-dark {
    --separator-color: var(--white-05);
  }
}

hr,
[role=separator][aria-orientation=horizontal] {
  border: none; /* reset default <hr> border */
  border-bottom: var(--separator-border);
  display: block;
  margin: 30px 0;
}

[role=separator][aria-orientation=vertical] {
  align-self: stretch; /* for flex containers */
  border-right: var(--separator-border);
}
