input[type=checkbox] {
  --checkbox-size: 16px;

  appearance: none;
  background-color: #fff;
  background-position: center;
  border: 1px solid var(--gray-04);
  display: inline-block;
  height: var(--checkbox-size);
  margin: 4px 6px 4px 4px;

  /** Prevent checkbox being smaller than 16px when labels have long text (breaking lines). */
  min-width: var(--checkbox-size);
  transition:
    background-color var(--default-transition),
    box-shadow var(--default-transition);
  width: var(--checkbox-size);

  /* SVG background doesn't work in IE without base64 ... */

  &:checked {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cG9seWdvbiBwb2ludHM9IjEwLjQ5IDE2LjkxIDYuMjkgMTIuNzEgNy43MSAxMS4zIDEwLjQ5IDE0LjA5IDE2LjI5IDguMjkgMTcuNzEgOS43IDEwLjQ5IDE2LjkxIiBmaWxsPSIjMzUzYjQ0Ii8+PC9zdmc+');
  }

  &:indeterminate {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48bGluZSB4MT0iOCIgeTE9IjEyIiB4Mj0iMTYiIHkyPSIxMiIgc3Ryb2tlPSIjMzUzYjQ0IiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=');
  }

  &:disabled {
    opacity: 0.25;
  }

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    background-color: #e6e6e6;
  }
}

@media (--touch) {
  input[type=checkbox] {
    --checkbox-size: 20px;
  }
}

.is-keynav input[type=checkbox]:focus {
  border-radius: 1px;
  box-shadow: var(--default-outline);
}
