html * {
  scrollbar-color: var(--gray-04) var(--gray-02);
  scrollbar-width: auto;
}

/* Webkit */

@supports not (scrollbar-width: light) {
  html {
    & *::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    & *::-webkit-scrollbar-track {
      background-color: var(--gray-02);
    }

    & *::-webkit-scrollbar-corner {
      background-color: var(--gray-02);
    }

    & *::-webkit-scrollbar-thumb {
      background-color: var(--gray-04);

      &:active {
        background-color: var(--gray-06);
      }

      &:hover {
        background-color: var(--gray-05);
      }
    }
  }
}
