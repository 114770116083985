@import 'ui/responsive-variables.css';
@import 'ui/toolbar-variables.css';

.d-legacy-toolbar {
  align-items: center;
  background-color: var(--d-legacy-toolbar-bg);
  display: flex;
  height: var(--map-toolbar-height);
  overflow-x: hidden;
  padding: 0 var(--default-page-margin);
  vertical-align: middle;

  /*
   * We want to apply margin left to all buttons which are not preceded by a d-legacy-toolbar-separator.
   * We have a different component structure in maps subnav, that is why we don't want this style for
   * such subnav (which is also a d-legacy-toolbar).
   */
  &:not(.d-subnav) > :not(.d-legacy-toolbar-separator) {
    & + .button,
    & + .d-dropdown-btn {
      margin-left: var(--d-legacy-toolbar-gap);
    }
  }

  & > * {
    flex-shrink: 0;
  }

  & .collapsibles-dropdown-btn {
    display: none;
  }

  &.is-resizable.with-dropdown .collapsibles-dropdown-btn {
    display: inline-block;
  }
}

.d-legacy-toolbar-separator {
  background-color: rgba(0, 0, 0, 0.2);
  height: 30px;
  margin: 0 20px;
  width: 1px;
}
