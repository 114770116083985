:root {
  --space-025: calc( 0.25 * var(--space-1));
  --space-05:  calc( 0.5  * var(--space-1));
  --space-1:   4px;
  --space-2:   calc( 2    * var(--space-1));
  --space-3:   calc( 3    * var(--space-1));
  --space-4:   calc( 4    * var(--space-1));
  --space-5:   calc( 5    * var(--space-1));
  --space-6:   calc( 6    * var(--space-1));
  --space-8:   calc( 8    * var(--space-1));
  --space-10:  calc(10    * var(--space-1));
  --space-12:  calc(12    * var(--space-1));
  --space-16:  calc(16    * var(--space-1));
  --space-20:  calc(20    * var(--space-1));
  --space-24:  calc(24    * var(--space-1));
}
