.float-left {
  float: left;
}

.float-right {
  float: right;
}

.position-centered {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
