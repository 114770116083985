.purpose-of-request-preview {
  display: none;
}

.d-purpose-of-request-badge--flyout {
  padding: 0 var(--space-2);
  width: 240px;
}

@media (--md-up) {
  .d-purpose-of-request-badge {
    display: inline-flex;
    max-width: 240px;
    min-width: 135px;
    width: 15vw;

    & .button {
      flex: 1;
    }
  }

  .purpose-of-request-preview {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    line-height: var(--small-line-height);
    max-height: var(--button-size);
    max-width: 100%;
    min-width: 0;
  }

  .purpose-of-request-preview-reason {
    font-weight: var(--default-font-weight);
    max-width: 100%;
  }
}
