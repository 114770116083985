@import 'ui/responsive-variables.css';

.progress-spinner {
  animation: rotate 1s infinite linear;
  border: 6px solid #e9e9e9;
  border-left-color: var(--primary-color);
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  flex-shrink: 0;
  height: 48px;
  transform: translateZ(0); /* trigger hardware acceleration */
  width: 48px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.d-progress-spinner {
  align-items: center;
  display: flex;
  overflow: hidden;
  pointer-events: none;
  z-index: 19;

  &.d-progress-spinner-column {
    flex-direction: column;

    & .progress-spinner-label {
      margin-top: 0.666em;
    }
  }

  &:not(.d-progress-spinner-column) .progress-spinner-label {
    margin-left: 0.666em;
  }
}

.d-progress-spinner-s .progress-spinner {
  border-width: 4px;
  height: var(--default-component-size);
  width: var(--default-component-size);
}

.d-progress-spinner-xs {
  & .progress-spinner {
    border-width: 2px;
    height: 18px;
    margin: 3px;
    width: 18px;
  }

  & .progress-spinner-label {
    margin-left: 5px;
  }

  &.d-progress-spinner-gray .progress-spinner {
    border-color: var(--gray-04);
    border-left-color: transparent;
  }
}
