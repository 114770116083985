/*
 * We cannot easily remove these global variables, because they're used in multiple places.
 *
 * PLEASE DO NOT ADD MORE VARIABLES HERE! (Unless they're used in multiple places, too.)
 */
:root {
  --button-size-m: 32px;
  --button-size-s: 24px;
}

@media (--touch) {
  :root {
    --button-size-m: 44px;
    --button-size-s: 32px;
  }
}
