.ellipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiline-ellipsis {
  --ellipsis-lines: 3;

  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: var(--ellipsis-lines);
  overflow: hidden;
}
