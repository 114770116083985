@import 'ui/responsive-variables.css';
@import 'ui/icon/icon-variables.css';
@import 'ui/button-variables.css';
@import 'ui/toolbar-variables.css';

:root {
  --view-header-bg: white; /* THEMING API */
}

.d-subnav {
  align-items: center;
  background: var(--view-header-bg);
  border-bottom: 1px solid var(--gray-03);
  display: flex;
  font-size: var(--font-size-3);
  height: var(--default-view-header-height);
  padding: 0 var(--default-page-margin);

  & > .d-subnav--layer-controls,
  & > .button,
  & > .d-info-button,
  & > .d-dropdown-btn {
    flex-shrink: 0;
    margin-left: var(--d-legacy-toolbar-gap);
  }

/* CADENZA-28255
Theory: The h1 of the subnav-content of header.xhtml is not read when the childnodes of the subnav are added.
We currently don't know it and if that theory would be right, we don't know, why that's the case.
So the fix for the moment is to always move the h1 to the beginning of the subnav via CSS, because we are not aware of a use
case where this would be wrong. We are also moving the buttons and spacer so that each item is in its place (some buttons
to the left and some to the right).
 */
  & > .d-page-title {
    flex-shrink: 1;
    order: -1;
  }

  &>.page-secondary-title {
     order: 0;
  }

  & .button {
    order: 1;
  }

  & .button#subnav-permalink-button,
  & .button.export-button {
    order: 3;
  }

  &>.d-spacer {
    order: 2;
   }

  & > .button > .button--label {
    display: none;
  }

}

.d-page-title {
  align-items: center;
  display: flex; /* ignore whitespace */
  flex-shrink: 1;
  font-family: var(--heading-font-family);
  font-weight: var(--bold-font-weight);

  /* line-height moves text to the top, making it unaligned with text in the subnav,
   * but removing it cuts off the bottom parts of letters like g or j. */
  line-height: 1.2em;
  margin: 0;

  /* 12px space between page title and first button: 8px + 4px margin-left from button */
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;

  &.d-page-title--no-right-margin {
    margin-right: 0;
  }

  & > .d-icon {
    flex-shrink: 0;
    height: 18px;
    margin-right: 4px;
    width: 18px;
  }

  & span {
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.dark-bg {
    color: var(--white-08);

    & .d-page-title {
      color: var(--white-08);
    }
  }

  & .button.button-borderless {
    background-color: var(--white-02);
  }

  & .collapsibles-dropdown-btn {
    z-index: 18; /* Should be at least the same as the value of the z-index of the d-on-demand-view, since it's after in the hierarchy, it will be shown on top */
  }
}

.d-permalink-menu {
  padding: 8px 16px;
  width: 270px;
}

@media (--touch) {
  .d-subnav :not(.button-icon).button {
    /* min-width, padding, width are from .button-icon */
    min-width: 0;
    padding: 0;

    & svg {
      margin-right: 0;
    }

    & span {
      display: none;
    }
  }
}

@media (--md-up) {
  .d-subnav {
    & > .button:not(.button-icon) {
      height: var(--button-size-s);

      & > .d-icon {
        height: var(--icon-size-xs);
        width: var(--icon-size-xs);
      }

      & .button--label {
        display: inline;
      }
    }
  }
}
