/**
 * Similar to .is-hover-visible, but elements are hidden in a way that they do not consume space in the layout.
 *
 * Caution: This (intentionally) causes layout jank.
 * Use this utility only if required by the design, otherwise use .is-hover-visible.
 */
@media (--hover) {
  .is-hover-displayed {
    :not(:is(.is-keynav &, .d-hover-context:is(:hover, .is-hover-active) &))& {
      display: none;
    }
  }
}
