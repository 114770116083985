.alert-dialog--title {
  font-weight: var(--bold-font-weight);
  margin-bottom: 8px; /* UX said to have this only in this use case for now, because it was hard to figure out the implications if set generally for all modals */

  &:only-child {
    margin: 4px 0;
  }
}

.alert-dialog-info .d-modal--header,
.alert-dialog-info .d-modal--title {
  color: var(--color-info-dark);
}

.alert-dialog-success .d-modal--header,
.alert-dialog-success .d-modal--title {
  color: var(--color-success-dark);
}

.alert-dialog-warning .d-modal--header,
.alert-dialog-warning .d-modal--title {
  color: var(--color-warning-dark);
}

.alert-dialog-error .d-modal--header,
.alert-dialog-error .d-modal--title {
  color: var(--color-error);
}
