dialog {
  bottom: auto;
  box-sizing: border-box;
  color: var(--default-color);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  overflow: visible;
  position: fixed;
  transition: opacity var(--default-transition);

  &.initializing {
    opacity: 0;
  }

  &:focus {
    outline: none;
  }

  /* Do *not* group the following two selectors together. It does't work in IE. */
  &::backdrop {
    background-color: var(--gray-06);
  }

  & + .backdrop {
    background-color: var(--gray-06);
  }
}

.d-dropdown-heading {
  color: var(--gray-05);
  font-size: var(--default-font-size);
  font-weight: var(--bold-font-weight);
  line-height: 24px;
  margin: 0;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
}
