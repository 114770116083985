@import 'ui/responsive-variables.css';

:root {
  --d-menu--item-padding: 8px;
}

.d-menu {
  max-width: 80%;

  & hr {
    border-color: var(--gray-03);
    margin: 4px var(--default-page-margin);
  }

  & > :first-child {
    margin-top: var(--d-menu--item-padding);
  }

  & > :last-child {
    margin-bottom: var(--d-menu--item-padding);
  }
}

.d-menu > [role=group] > label:first-child {
  color: var(--gray-06);
  display: block;
  font-size: var(--small-font-size);
  font-weight: var(--bold-font-weight);
  padding: 4px 16px;
}

.d-menu [role^=menuitem] {
  background-color: transparent;
  box-shadow: none !important; /* no focus outline, because we indicate focus using the background-color */
  display: flex;
  font-weight: normal;
  justify-content: flex-start;
  min-height: 34px;
  padding: 0 var(--default-page-margin);
  width: 100%; /* for IE – The container d-menu is set to static width, which the entry should fill */

  & > .button--label {
    margin-right: auto;
  }

  &.d-menu--item-selected {
    background-color: var(--gray-03);
    color: var(--gray-08);
    font-weight: var(--bold-font-weight);
  }

  &:not(:disabled):hover {
    background-color: var(--gray-03);
  }

  &:active:not(:disabled) {
    background-color: var(--gray-04);
    color: var(--gray-08);
  }

  &[aria-expanded=true] {
    background-color: var(--gray-03);
    color: var(--gray-08);

    &:disabled {
      background-color: transparent;
      color: var(--gray-03);
    }

    &:hover:not(:disabled) {
      background-color: var(--gray-04);
    }

    &:active:not(:disabled) {
      background-color: var(--gray-05);
    }
  }

  & .d-icon:not(.d-submenu-arrow) {
    height: 18px;
    width: 18px;

    &:first-child {
      margin-right: 8px;
    }
  }

  &:not(:disabled) .d-icon:not(.d-submenu-arrow, .d-icon-primary, .d-icon-error, .d-icon-warning) {
    color: var(--gray-06);
  }
}

.d-menu [role=menuitemradio][aria-checked="true"] {
  background-color: var(--gray-03);
  color: var(--gray-08);
  font-weight: var(--bold-font-weight);
}

.d-submenu-arrow {
  margin: 0 0 0 auto;
}

.is-keynav .d-menu [role^=menuitem]:focus {
  background-color: var(--gray-02);
}

.d-menu .d-alert.d-alert-small {
  margin: 16px 8px 12px;
  max-width: 320px;
  min-width: 240px;
}
