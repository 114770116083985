.d-alert {
  --bg: transparent;
  --color: var(--default-color);
  --font-size: var(--default-font-size);
  --icon: none;
  --icon-color: var(--color);
  --icon-gap: 4px;
  --icon-size: 20px;
  --line-height: var(--default-line-height);
  --padding-x: 8px;
  --padding-y: 12px;
  --text-offset: calc((var(--icon-size) - var(--line-height)) / 2);
  --p-margin: 8px;

  background-color: var(--bg);
  color: var(--color);
  display: block;
  font-family: var(--default-font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  padding: calc(var(--padding-y) + var(--text-offset)) var(--padding-x);
  position: relative;
  text-align: left;

  &:where(.d-alert-with-button) .d-alert--text::before,
  &:where(:not(.d-alert-with-button))::before {
    background-color: var(--icon-color);
    content: '';
    display: block;
    height: var(--icon-size);
    mask-image: var(--icon);
    mask-size: var(--icon-size);
    width: var(--icon-size);
  }

  &:not(.d-alert-small, .d-alert-xs) {
    padding-left: calc(var(--padding-x) + var(--icon-size) + var(--icon-gap));

    &.d-alert-with-button .d-alert--text::before,
    &:not(.d-alert-with-button)::before {
      left: var(--padding-x);
      position: absolute;
      top: var(--padding-y);
    }
  }

  &:empty {
    display: none;
  }

  &:where(:not(:last-child)) {
    margin-bottom: 16px;
  }

  & .d-alert--text {
    display: block;
  }

  & p:not(:last-child) {
    margin-bottom: var(--p-margin);
  }

  & ul {
    list-style: square inside;
    margin-bottom: 4px;
    margin-top: 4px;
    padding: 0;

    /* reduce distance between bullet point and text to 4px */
    & li span {
      left: -6px;
      position: relative;
    }
  }

  /* reset legacy CSS (/wps/style.css) */
  & .title {
    font-weight: var(--bold-font-weight);
    padding: 0;
    text-align: left;
    width: auto;

    &:empty {
      display: none;
    }
  }
}

.d-alert-success {
  --bg: var(--alert-color-success-background);
  --color: var(--alert-color-success);
  --icon: url('~@disy/cadenza-icons/check.svg');
}

.d-alert-info {
  --bg: var(--alert-color-info-background);
  --color: var(--alert-color-info);
  --icon: url('~@disy/cadenza-icons/info.svg');
}

.d-alert-warning {
  --bg: var(--alert-color-warning-background);
  --color: var(--alert-color-warning);
  --icon: url('~@disy/cadenza-icons/warning.svg');
  --icon-color: var(--color-warning-dark);
}

.d-alert-error {
  --bg: var(--alert-color-error-background);
  --color: var(--alert-color-error);
  --icon: url('~@disy/cadenza-icons/error.svg');
}

:where(.d-alert-opaque) {
  &.d-alert-info {
    --bg: var(--alert-color-info-background-opaque);
  }

  &.d-alert-warning {
    --bg: var(--alert-color-warning-background-opaque);
  }

  &.d-alert-error {
    --bg: var(--alert-color-error-background-opaque);
  }
}

:where(.d-alert-xs).d-alert-warning {
  --color: var(--color-warning-dark);
}

:where(.dark-bg) {
  & .d-alert-success {
    --bg: var(--alert-color-success-background-invert);
    --color: var(--alert-color-success-invert);
  }

  & .d-alert-info {
    --bg: var(--alert-color-info-background-invert);
    --color: var(--alert-color-info-invert);
  }

  & .d-alert-warning {
    --bg: var(--alert-color-warning-background-invert);
    --color: var(--alert-color-warning-invert);
    --icon-color: var(--alert-color-warning-invert);
  }

  & .d-alert-error {
    --bg: var(--alert-color-error-background-invert);
    --color: var(--alert-color-error-invert);
  }
}

.d-alert-small,
.d-alert-xs {
  &.d-alert-with-button .d-alert--text::before,
  &:not(.d-alert-with-button)::before {
    float: left;
    margin-right: var(--icon-gap);
    margin-top: calc(-1 * var(--text-offset));
  }
}

.d-alert-small {
  --icon-size: 16px;
  --padding-y: 8px;
  --p-margin: 4px;

  &:where(:not(:last-child)) {
    margin-bottom: 6px;
  }
}

.d-alert-xs {
  --bg: transparent;
  --icon-gap: 2px;
  --p-margin: 4px;

  padding: 0;

  &:where(:not(:last-child)) {
    margin-bottom: 4px;
  }

  @media (--hover) {
    --icon-size: 16px; /* icon size 'xs' */
  }
}

.d-alert-with-button {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  & .d-alert--text + * {
    margin-left: 8px;
  }
}

.d-alert-with-button-bottom {
  --button-gap: 8px;

  & .d-alert--text ~ * {
    margin: var(--button-gap) var(--button-gap) 0 0;
  }

  &.d-alert-small {
    --button-gap: 4px;
  }
}
