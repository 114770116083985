.focus-outline,
.focus-outline-keynav {
  outline: none; /* disable the native focus outline (for :focus-visible) */
  transition: box-shadow var(--default-transition); /* animate our focus outline */
}

.focus-outline,
.is-keynav .focus-outline-keynav {
  &:focus {
    border-radius: 1px; /* add rounded corners to the outline */
    box-shadow: var(--default-outline);
    outline: var(--default-native-outline);
  }
}
