@import 'ui/responsive-variables.css';
@import 'ui/icon/icon-variables.css';

.d-sidebar-header {
  height: 50px;
}

.d-sidebar--backdrop {
  background-color: var(--gray-07);
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var(--sidebar-backdrop-z-index);
}

.d-sidebar {
  background-color: var(--gray-01-opaque);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: var(--left-sidebar-z-index);

  &.is-overlay,
  &.is-animated {
    transition: transform 100ms;

    &[aria-hidden=true] {
      transform: translateX(-100%);
    }
  }

  & .form-group {
    margin-bottom: 10px;

    & > label {
      margin-bottom: 0;
      padding: 5px 5px 5px 0;
    }
  }

  & .form-control {
    background-color: var(--white-09);
  }
}

.d-sidebar-back {
  margin-right: 5px;
}

.d-sidebar-reset {
  margin-left: 10px;
}

.d-sidebar-close {
  margin-left: 5px;
}

.d-sidebar-section-title {
  margin: 0 0 5px 0;
  padding: 10px 0;
}

.d-sidebar-splitline {
  border: none;
  border-bottom: 1px solid var(--gray-03);
  margin: 12px 0;
}

.d-sidebar-section:not(:last-child) {
  margin-bottom: 25px;
}

.d-sidebar-content {
  flex: 1;
  overflow-x: hidden; /* overflow: hidden auto; is not supported in IE and Edge */
  overflow-y: auto;
  padding: 10px var(--default-page-margin);
}

.d-sidebar-footer {
  background-color: var(--gray-01-opaque);
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  display: flex;
  padding: 10px;
  position: relative;

  & :not(:first-child) {
    margin-left: 5px;
  }

  & .button {
    flex: 1 1 0;
  }
}

@media (--xs-only) {
  .d-sidebar {
    bottom: 15px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
}

@media (--sm-up) {
  .d-sidebar {
    max-width: 360px;

    &.is-overlay,
    &.is-animated {
      left: 0;
      position: absolute;
      top: 0;
    }

    & .form-group {
      margin-bottom: 5px;

      & > label {
        padding: 3px 0;
      }
    }

    & .d-sidebar-footer {
      display: none;
    }
  }

  h2.d-sidebar-section-title {
    padding: 4px 0;
  }

  /* Defined during the filter-reimplementation project: for size SM the sidebar backdrop of the filter controller should cover the content */
  .d-sidebar[aria-hidden="false"] + .d-sidebar--backdrop {
    display: block;
  }
}

@media (--md-up) {
  .d-sidebar {
    max-width: 320px;
  }

  /* For sizes MD and more, no backdrop */
  .d-sidebar[aria-hidden="false"] + .d-sidebar--backdrop {
    display: none;
  }
}
