a:where(:any-link:not(.button)),
.d-anchor {
  --bg: transparent;
  --bg-hover: rgba(0, 0, 0, 0.1);
  --bg-active: rgba(0, 0, 0, 0.2);
  --color: var(--link-color);
  --color-hover: var(--link-color);
  --color-visited: var(--visited-link-color);

  background-color: var(--bg);
  border: none;
  border-radius: 1px;
  box-shadow: 0 0 0 2px var(--bg);
  color: var(--color);
  cursor: pointer;
  flex-shrink: 0;

  /* Inherit these properties for button.d-anchor. (<a> does this by default.) */
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  min-height: 0;
  min-width: 0;
  outline: none;
  text-decoration: underline;
  transition:
    background-color var(--default-transition),
    box-shadow var(--default-transition);

  &::-moz-focus-inner {
    border: 0;
  }

  &:visited {
    color: var(--color-visited);
  }

  &:hover {
    background-color: var(--bg-hover);
    color: var(--color-hover);
  }

  &:active {
    background-color: var(--bg-active);
  }

  &:disabled {
    color: var(--gray-03);
    pointer-events: none;
  }
}

button.d-anchor {
  padding: 0;
}

.is-keynav {
  & a:where(:any-link:not(.button)):focus,
  & .d-anchor:focus {
    box-shadow: var(--default-outline);
  }
}

.dark-bg {
  & a:where(:any-link:not(.button)),
  & .d-anchor {
    --color: var(--white-08);
    --color-hover: var(--white-08);
    --color-visited: var(--white-08);
    --bg-hover: rgba(255, 255, 255, 0.1);
    --bg-active: rgba(255, 255, 255, 0.2);
  }
}
