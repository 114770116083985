.d-keymap {
  & table {
    table-layout: fixed;
    width: 100%;
  }

  & td:first-child {
    width: 60%;
  }

  & kbd {
    font-family: var(--monospace-font-family);

    &.key {
      border: 1px solid var(--gray-03);
      border-radius: 3px;
      padding: 0 2px;
      white-space: nowrap;

      &:not(:first-child) {
        margin-left: 2px;
      }

      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }
}

.d-hotkey.active kbd.key {
  background-color: var(--gray-02);
}
