@import 'ui/responsive-variables.css';
@import 'ui/icon/icon-variables.css';

:root {
  --main-header-height: var(--default-view-header-height); /* THEMING API */
  --main-header-bg: var(--primary-color); /* THEMING API */
}

.d-topnav {
  align-items: center;
  background: var(--main-header-bg);
  display: flex;
  height: var(--main-header-height);
  padding: 0 var(--default-page-margin);

  & > :not(.d-spacer) {
    margin-left: 5px;
  }

  & .d-topnav--branding-middle-left {
    display: flex;
  }

    /* Fixed width elements */
  & .d-topnav--branding-left,
  & .topnav--menu-left,
  & .topnav--menu-right,
  & .d-topnav--branding-right {
    flex-grow: 0;
    flex-shrink: 0;
  }

  /* Flexible width elements */
  & .d-topnav--branding-middle-left,
  & .d-topnav--branding-middle-right {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }

  /* Responsive UI - following elements are hidden on smaller screens, see media rules below, which show them. */
  & .d-topnav--branding-left,
  & .d-topnav--branding-middle-right,
  & .d-topnav--branding-right {
    display: none;
  }

  @media (--sm-up)  {
    & .d-topnav--branding-middle-right {
      display: flex;
    }
  }

  @media (--md-up) {
    & .d-topnav--branding-right {
      display: flex;
    }
  }

  @media (--lg-up) {
    & .d-topnav--branding-left {
      display: flex;
    }
  }
}

.d-topnav--branding-text {
  color: var(--default-color);
  font-family: var(--heading-font-family);
  font-size: 26px;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

h2.d-topnav--branding-text {
  font-size: var(--font-size-4);
}

.d-topnav--custom-menu {
  margin-right: 5px;
}
