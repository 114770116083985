@import 'ui/responsive-variables.css';

.d-help-dialog {
  & .d-modal--body {
    padding: 0;
  }

  &:not(.is-fullscreen) .d-modal--body {
    height: calc(100vh - 150px);
    max-height: 500px;
  }
}

.d-help-dialog--iframe {
  border: none;
  height: 100%;
  width: 100%;
}
